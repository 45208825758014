<template>
  <form
    class="flex flex-col items-start px-9.5"
    @submit.prevent="onForgotPassword"
  >
    <div class="mb-6 w-full">
      <div class="mb-1 font-medium">Email Address</div>
      <UiInput
        v-model="signInForm.email"
        class="flex-1"
        name="email"
        :placeholder="$t('enterYourEmailAddress')"
      />
    </div>

    <UiButton
      :loading="isSubmitting"
      type="submit"
      color="primary"
      class="mb-4 w-full"
    >
      {{ $t("resetPassword") }}
    </UiButton>
  </form>
</template>

<script setup lang="ts">
import { useForm } from "vee-validate";
import { object, string } from "yup";
import { ForgotForm } from "@/types/api/auth.types";
import { userApi } from "@/api";
import { useGeneralStore } from "~/stores/general";
const { t } = useI18n();

const generalStore = useGeneralStore();

const { openSystemDialog } = useSystemDialogs();

const initialSignInForm: ForgotForm = {
  email: "",
};

const schema = object().shape({
  email: string().email().required().label(t("emailAddress")),
});

const signInForm = reactive(
  JSON.parse(JSON.stringify(initialSignInForm)),
) as ForgotForm;

const {
  handleSubmit: handleSubmitSignIn,
  resetForm: resetFormSignIn,
  setErrors: setErrorsSignIn,
  isSubmitting,
} = useForm({
  initialValues: initialSignInForm,
  validationSchema: schema,
});

const onForgotPassword = handleSubmitSignIn(async (values) => {
  const params = {
    ...values,
  };
  const { error } = await userApi.recoveryPasswordRequest(params);

  if (!error.value) {
    resetFormSignIn();
    generalStore.isSignInDrawerOpen = false;
    openSystemDialog({
      type: "alert",
      title: `${t("success")}!`,
      text: `${t("recoverPasswordMessage")}.`,
    });
  } else {
    setErrorsSignIn(responseErrorToObjectErrors(error?.value?.data?.errors));
  }
});
</script>
